import { atom } from 'jotai';

export interface EsensorQuery {
  deviceEUI: string;
  jInfo: string;
}

export type DataObj = {
  deviceID: string;
  PacketID: number;
}

export type Esensor = {
  deviceEUI: string;
} & (Kiln | RG | RH_Tank | Weather);

export type EsensorChart = {
  timestamp: string;
  value: number;
}

// Kiln deviceEUI: "A84041F5F1881CF7"
export type Kiln = DataObj & {
  BattStatus: number;
  Humidity: number;
  ITemp: number;
  Vbat: number;
  XTemp: number;
  XType: number;
  XTypeName: string;
};

// RG deviceEUI: "2CF7F1C0609001CE"
export type RG = DataObj & {
  Hourly: number;
  Inches: number;
};

// RH deviceEUI: "2CF7F1C06090018F"
// Tank deviceEUI: "2CF7F1C06090013B"
export type RH_Tank = DataObj & {
  Height: number;
  Raw?: number;
  Gallons?: number;
  FuelVolume?: number;
};

// Weather deviceEUI: "2CF7F1C06090023A"
export type Weather = DataObj & {
  TempC: number;
  Humidity: number;
  BaroPress: number;
  BaroHex: number;
  WindDir: number;
  WindMax: number;
  WindAvg: number;
  HeatTemp: number;
  State: number;
};

export const eSensorsAtom = atom<Esensor[]>([]);

export const eSensorChartAtom = atom<EsensorChart[]>([]);