import { atom } from 'jotai';

export interface Building {
  BuildingID: string;
  Timestamp: string;
  NChans: number;
  StartChan: number;
  NCharging: number;
  NWaiting: number;
  PhA: number;
  PhB: number;
  PhC: number;
  Grid: number;
  Load: number;
  Solar: number;
  Demand: number;
  DayPeak: number;
  PeakTime: number;
  recnum: number;
}

export const buildingAtom = atom<Building[]>([]);