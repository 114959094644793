import React from 'react';
import { useAtom } from 'jotai';
import { toggleCharging } from '../atoms-types/chargers';
import Button from "@mui/material/Button";

const TogglePowerEnergyButton = () => {
  const [viewType, setViewType] = useAtom(toggleCharging);

  const toggleView = () => {
    setViewType(prevType => prevType === 'CPower' ? 'CEnergy' : 'CPower');
  };

  return (
    <Button
      onClick={toggleView}
      style={{
        backgroundColor: viewType === 'CPower' ? 'teal' : 'goldenrod',
        color: 'white',
        marginBottom: '10px'
      }}
    >
      {viewType === 'CPower' ? 'Switch to Energy' : 'Switch to Power'}
    </Button>
  );
};

export default TogglePowerEnergyButton;
