import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAtom } from 'jotai';
import useWebSocket from '../wsHook';
import { eSensorsAtom, Esensor, EsensorQuery, Kiln, RG, RH_Tank, Weather } from '../atoms-types/eSensors';
import { Card, CardContent, Divider, Box, Typography } from '@mui/material';

const ESensorHandler = () => {
  const { messages } = useWebSocket('wss://odp.golf/websocket', ['sensor']);
  const [ eSensors, setESensorData ] = useAtom(eSensorsAtom);

  const { isFetching, isError, error } = useQuery({
    queryKey: ['fallbackeSensors'],
    staleTime: Infinity,
    queryFn: async () => {
      const response = await axios.get<EsensorQuery[]>('https://odp.golf/api/fallback-sensor');
      const data = response.data;

      UpdateEsensorData(data);
      console.log("all fallback Esensor: ", data);
      return data;
    }
  });

const UpdateEsensorData = (results: EsensorQuery[]) => {
  const parsedData = results.map((result) => {
    const { deviceEUI, jInfo } = result;
    let newData: Esensor;

    try {
      const JSONdata = JSON.parse(jInfo);

      // Ensure `JSONdata` adheres to one of the expected types
      if (deviceEUI === "A84041F5F1881CF7") {
        newData = { deviceEUI, ...JSONdata as Kiln };
      } else if (deviceEUI === "2CF7F1C0609001CE") {
        newData = { deviceEUI, ...JSONdata as RG };
      } else if (deviceEUI === "2CF7F1C06090023A") {
        newData = { deviceEUI, ...JSONdata as Weather }
      } else if (deviceEUI === "2CF7F1C06090018F" || deviceEUI === "2CF7F1C06090013B") {
        newData = { deviceEUI, ...JSONdata as RH_Tank };
      } else {
        console.warn(`Unknown sensor UID:`, result);
        return null; // Skip unknown sensors
      }

      return newData;
    } catch (error) {
      console.error(`Error parsing JSON for deviceEUI ${deviceEUI}:`, error);
      return null; // Skip faulty data
    }
  }).filter((data): data is Esensor => data !== null); // Remove null entries

  setESensorData((prev) => {
    // Merge existing data with new parsed data, avoiding duplicates by `deviceEUI`
    const updatedMap = new Map(
      [...prev, ...parsedData].map((sensor) => [sensor.deviceEUI, sensor])
    );

    return Array.from(updatedMap.values());
  });
};


  useEffect(() => {
    messages.forEach((messageObj) => {
      // console.log("message received: ", messageObj);
      const { channel, message } = messageObj;

      if (channel === 'sensor') {
        try {
          if (message) {
            // console.log('Updating sensor data:', message);
            const updatedSensor = message as Esensor; 
            setESensorData((prev) =>
              prev.map((sensor) =>
                sensor.deviceEUI === updatedSensor.deviceEUI
                  ? updatedSensor
                  : sensor
              )
            );
          } else {
            console.error('Invalid sensor payload format:', message);
          }
        } catch (error) {
          console.error('Failed to parse message:', error);
        }
      }
    });
  }, [messages, setESensorData]);

  const RG = eSensors.find((sensor) => sensor.deviceEUI === "2CF7F1C0609001CE") as Esensor & RG | undefined || { Inches: 0 };

  const renderLoadingOrError = () => {
    if (isFetching) {
      return <p>Loading...</p>;
    } else if (isError) {
      return <p>Error: {error.message}</p>;
    }
    return null;
  };

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: '10px' }}>
          <Typography variant="h3" sx={{ color: 'red' }}>{RG.Inches ? RG.Inches.toFixed(2) : "0.00"}</Typography>
          <Typography variant="body2" sx={{ color: 'red', ml: 0.5 }}>in</Typography>
        </Box>
        <br />
        {/* <Divider /> */}
        <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary', textAlign: 'center', mt: '5px' }}>
          Rainfall
        </Typography>
        <Divider sx={{ borderBottomWidth: '3px', borderColor: 'red' }} />
      </CardContent>
    </Card>
  );
}

export default ESensorHandler;