import React, { useState, useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ConvertTime from '../converters/timestampConverter';
import { eSensorChartAtom } from '../atoms-types/eSensors';
import { Card, CardContent, Select, MenuItem, Typography, FormControl, SelectChangeEvent } from '@mui/material';
import EsensorChart from '../charts-graphs/eSensorChart';
import EsensorChartButton from './eSensorGraphButton-handler';

export default function EsensorChartHandler() {
  const setEsensorChart = useSetAtom(eSensorChartAtom);
  const [selectedDevice, setSelectedDevice] = useState("2CF7F1C0609001CE");
  const [selectedField, setSelectedField] = useState("Hourly");

  // Mapping of selections to deviceEUI and selectedField
  const selectionMapping = {
    "Temperature (C)": { deviceEUI: "2CF7F1C06090023A", selectedField: "TempC" },
    "Humidity (%)": { deviceEUI: "2CF7F1C06090023A", selectedField: "Humidity" },
    "Barometric Pressure": { deviceEUI: "2CF7F1C06090023A", selectedField: "BaroPress" },
    "Wind Average (%)": { deviceEUI: "2CF7F1C06090023A", selectedField: "WindMax" },
    "Wind Direction": { deviceEUI: "2CF7F1C06090023A", selectedField: "WindDir" },
    "Wind Max": { deviceEUI: "2CF7F1C06090023A", selectedField: "WindMax" },
    "Rainfall (in)": { deviceEUI: "2CF7F1C0609001CE", selectedField: "Hourly" },
    "River Height (in)": { deviceEUI: "2CF7F1C06090018F", selectedField: "Height" },
    "Tank": { deviceEUI: "2CF7F1C06090013B", selectedField: "Raw" },
    "Kiln Temp (C)": { deviceEUI: "A84041F5F1881CF7", selectedField: "XTemp" },
    "Kiln Humidity (%)": { deviceEUI: "A84041F5F1881CF7", selectedField: "Humidity" },
  };

  const handleSelectionChange = (event: SelectChangeEvent) => {
    const selected = event.target.value as keyof typeof selectionMapping;
    const { deviceEUI, selectedField } = selectionMapping[selected];
    
    setSelectedDevice((prev) => (prev === deviceEUI ? "" : deviceEUI));
    setSelectedField((prev) => (prev === selectedField ? "" : selectedField));

    setTimeout(() => {
      setSelectedDevice(deviceEUI);
      setSelectedField(selectedField);
    }, 0);
  };


  const { refetch } = useQuery({
    queryKey: ['EsensorChart', selectedDevice],
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      if (!selectedDevice) return [];
      const response = await axios.get(
        `https://odp.golf/api/fallback-esensorchart/${selectedDevice}`
      );
      const data = response.data;
      UpdateEsensorChart(data);
      return data;
    },
    enabled: !!selectedDevice,
  });
    
    useEffect(() => {
    refetch(); // Force query refetch when selectedDevice changes
  }, [selectedDevice, refetch]);

  function UpdateEsensorChart(data: { Timestamp: string; jInfo: string }[]) {
    const updatedEsensorChart = data?.map((entry) => {
      const parsedJInfo = JSON.parse(entry.jInfo || '{}');
      const value = parsedJInfo[selectedField] ?? null;
      return {
        timestamp: ConvertTime(entry.Timestamp),
        value,
      };
    });
    setEsensorChart(updatedEsensorChart);
  }

  // const renderLoadingOrError = () => {
  //   if (isFetching) {
  //     return <p>Loading...</p>;
  //   } else if (isError) {
  //     return <p>Error: {error.message}</p>;
  //   }
  //   return null;
  // };

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <CardContent
        sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        {/* {renderLoadingOrError()} */}
        <Typography variant="h6" gutterBottom>
          Sensor Data
        </Typography>
        <FormControl fullWidth>
          <Select
            value={
              Object.keys(selectionMapping).find(
                (key) =>
                  selectionMapping[key as keyof typeof selectionMapping].deviceEUI === selectedDevice &&
                  selectionMapping[key as keyof typeof selectionMapping].selectedField === selectedField
              ) || ""
            }
            onChange={handleSelectionChange}
          >
            {Object.keys(selectionMapping).map((key) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <EsensorChart />
        <EsensorChartButton field = {selectedField} device = {selectedDevice} />
      </CardContent>
    </Card>
  );
}
