import { useEffect, useState } from 'react';

interface MessageObject<T> {
  channel: string;
  message: T;
}

const useWebSocket = <T,>(
  baseUrl: string, // Base WebSocket URL
  channels: string[], // Channels to subscribe
  action: string = "subscribe" // Default action
) => {
  const [messages, setMessages] = useState<MessageObject<T>[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const queryParams = new URLSearchParams();
    queryParams.append("action", action);
    channels.forEach((channel) => queryParams.append("channel", channel));

    const wsUrl = `${baseUrl}?${queryParams.toString()}`;
  useEffect(() => {
    // Dynamically create the WebSocket URL with query params

    console.log(`Connecting to WebSocket URL: ${wsUrl}`);

    // Create WebSocket instance with the constructed URL
    const socket = new WebSocket(wsUrl);
    console.log('WebSocket readyState:', socket.readyState);

    const handleOpen = () => {
      console.log('Connected to WebSocket server');
      setIsConnected(true);
    };

    const handleMessage = (event: MessageEvent) => {
      const parsedData: MessageObject<T> = JSON.parse(event.data);
      setMessages((prevMessages) => [...prevMessages, parsedData]);
    };

    const handleClose = (event: CloseEvent) => {
      console.log('WebSocket connection closed', event.code, event.reason, event.wasClean);
      setIsConnected(false);
    };

    const handleError = (event: Event) => {
      console.log('Error event', event);
    };

    socket.addEventListener('open', handleOpen);
    socket.addEventListener('message', handleMessage);
    socket.addEventListener('close', handleClose);
    socket.addEventListener('error', handleError);

    return () => {
      socket.removeEventListener('open', handleOpen);
      socket.removeEventListener('message', handleMessage);
      socket.removeEventListener('close', handleClose);
      socket.removeEventListener('error', handleError);
      socket.close();
    };
  }, [wsUrl]);

  return { messages, isConnected };
};

export default useWebSocket;



