import React, { useState, useEffect } from 'react';
import { APIProvider, Map, InfoWindow } from '@vis.gl/react-google-maps';
import { AdvancedMarker, Pin } from '@vis.gl/react-google-maps';
import { useAtom } from 'jotai';
import { allDevLocationAtom, deviceLocationAtom, DevLocation, devicePathAtom, allDeviceAtom } from '../atoms-types/devices';
import { mapCenterAtom, mapZoomAtom } from '../atoms-types/maps';
import { useParams, useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import { useFetchDevicePath } from '../handlers/fetchDevicePath';
import { Polyline } from '../Polyline';
// import MapCenterButton from '../buttons/mapCenterButtons';

const GolfMap = () => {
  const { deviceID } = useParams<{ deviceID?: string }>();
  const [devLocations, ] = useAtom(deviceID ? deviceLocationAtom : allDevLocationAtom);
  const [mapCenter, setMapCenter] = useAtom(mapCenterAtom);
  const [zoomState, setZoomState] = useAtom(mapZoomAtom);
  const [devicePath] = useAtom(devicePathAtom);
  const [selectedCar, setSelectedCar] = useState<DevLocation | null>(null);
  const navigate = useNavigate();
  const [, setFetchPath] = useState<boolean>(false);
  const [allDevices] = useAtom(allDeviceAtom);

  console.log("golfmap", devLocations);

  const handleMarkerClick = (devLocation: DevLocation) => {
    setSelectedCar(selectedCar === devLocation ? null : devLocation);
  };
  
  const isValidLocation = (lat: number, lng: number) => Number.isFinite(lat) && Number.isFinite(lng);

  useEffect(() => {
    if (deviceID) {
      const location = devLocations.find(location => location.deviceID === deviceID);
      if (location) {
        setMapCenter({ lat: location.lat, lng: location.lng });
        setZoomState({ zoom: 20 });
        setFetchPath(true);
        console.log('Centering map to device:', location);
      }
    } else {
      setZoomState({ zoom: 17 });
      setFetchPath(false);
      console.log('Displaying map normally');
    }
  }, [deviceID, devLocations, setMapCenter, setZoomState]);

  const { isFetching, isError, error } = useFetchDevicePath(deviceID ?? null);

  const getMarkerStyle = (deviceID: string) => {
    const device = allDevices.find(dev => dev.deviceID === deviceID);
    const deviceName = device ? device.name : deviceID;
    if (deviceID.startsWith('tsg')) {
      return {
        background: 'cadetblue',
        borderColor: 'teal',
        glyph: deviceName,
      };
    } else if (deviceID.startsWith('sg')) {
      return {
        background: 'indianred',
        borderColor: 'firebrick',
        glyph: deviceName,
      };
    }
    return {
      background: 'grey',
      borderColor: 'grey',
      glyph: deviceName,
    };
  };

  const handleDeviceClick = (deviceID: string) => {
    navigate(`/${deviceID}`);
  };

  useEffect(() => {
    if (selectedCar) {
      const location = devLocations.find(location => location.deviceID === selectedCar.deviceID);
      if (location) {
        setMapCenter({ lat: location.lat, lng: location.lng });
        setZoomState({ zoom: 18 });
      }
    }
  }, [selectedCar, devLocations, setMapCenter, setZoomState]);

  if (isFetching) return <div>Loading...</div>;
  if (isError) return <div>Error: {error?.message}</div>;

  const mostRecentLocation = devicePath[devicePath.length - 1];

  return (
    <div>
      <APIProvider apiKey="AIzaSyDYZA7VHWtg3w6W0W1QDwkHAoo_MuQV_IU">
        <Map
          key={JSON.stringify(mapCenter)}
          style={{ width: "100%", height: '750px' }}
          defaultCenter={mapCenter}
          defaultZoom={zoomState.zoom}
          mapId='MAP_ID'
          mapTypeId = 'satellite'
        >
          {devLocations?.map((location: DevLocation, index: number) => {
            if (isValidLocation(location.lat, location.lng)) {
              const markerStyle = getMarkerStyle(location.deviceID);
              return (
                <React.Fragment key={index}>
                  <AdvancedMarker
                    position={{ lat: location.lat, lng: location.lng }}
                    onClick={() => handleMarkerClick(location)}
                  >
                    {/* <Pin
                      background={markerStyle.background}
                      borderColor={markerStyle.borderColor}
                      glyphColor={'white'}
                      glyph={markerStyle.glyph}
                      scale={1}
                    /> */}
                    <div className="custom-marker" style={{ backgroundColor: markerStyle.background, borderColor: markerStyle.borderColor }}>
                      <div className="custom-marker-text">
                        {markerStyle.glyph}
                      </div>
                    </div>
                  </AdvancedMarker>
                  {selectedCar === location && (
                    <InfoWindow
                      position={{ lat: location.lat, lng: location.lng }}
                      onCloseClick={() => setSelectedCar(null)}
                    >
                      <div>
                        <p>Device: {location.deviceID}</p>
                        <p>lat: {location.lat.toFixed(3)}</p>
                        <p>lng: {location.lng.toFixed(3)}</p>
                        <Button variant="contained" style={{ backgroundColor: 'seagreen' }} onClick={() => handleDeviceClick(selectedCar.deviceID)}>
                          to device
                        </Button>
                      </div>
                    </InfoWindow>
                  )}
                </React.Fragment>
              );
            }
            return null;
          })}
          {deviceID && devicePath.length > 0 && (
            <>
              <Polyline
                path={devicePath.map(({ lat, lng }) => ({ lat, lng }))}
                strokeColor={'goldenrod'}
              />
              {isValidLocation(mostRecentLocation.lat, mostRecentLocation.lng) && (
                <div></div>
                // <AdvancedMarker
                //   position={{ lat: mostRecentLocation.lat, lng: mostRecentLocation.lng }}
                //   onClick={() => handleMarkerClick(mostRecentLocation)}
                // >
                //   {/* <Pin
                //     background={getMarkerStyle(deviceID).background}
                //     borderColor={getMarkerStyle(deviceID).borderColor}
                //     glyphColor={'white'}
                //     glyph={getMarkerStyle(deviceID).glyph}
                //     scale={1}
                //   /> */}
                // </AdvancedMarker>
              )}
            </>
          )}
        </Map>
      </APIProvider>
      {deviceID && (
        <Button
          onClick={() => navigate('/location/map')}
          style={{
            backgroundColor: 'seagreen',
            color: 'white'
          }}
        >
          View All Devices
        </Button>
      )}
      {/* <MapCenterButton /> */}
    </div>
  );
};

export default GolfMap;


