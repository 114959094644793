import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { AppProvider, type Navigation, type Session } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { StaffNav, AdminNav } from './navigationLayout';
import SidebarFooterAccount from './sidebarFooterAccount';
import { Typography } from '@mui/material';

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

const ROLE_HIERARCHY: Record<string, Navigation> = {
  Staff: StaffNav,
  Manager: AdminNav, // Managers use the same navigation as Admins
  Admin: AdminNav,
  Super: AdminNav, // Supers also use the AdminNav layout
};

export default function Toolbar() {
  const navigate = useNavigate();
  const { user, isAuthenticated, loginWithRedirect, logout, isLoading } = useAuth0();

  // Create the session object based on Auth0 state
  const session: Session | null = isAuthenticated
    ? {
        user: {
          name: user?.name || '',
          email: user?.email || '',
          image: user?.picture || '',
        },
      }
    : null;

  // Determine the user roles, default to 'Staff' if no roles are assigned
  const roles = React.useMemo(() => {
    const userRoles = user?.['https://odp.golf/roles'] || [];
    if (userRoles.length === 0) {
      console.log('No roles found, defaulting to Staff');
      return ['Staff'];
    }
    return userRoles;
  }, [user]);

  console.log('User roles:', roles);

  // Select navigation based on role hierarchy
  const NAVIGATION: Navigation = React.useMemo(() => {
    if (!isLoading && isAuthenticated) {
      for (const role of roles) {
        if (ROLE_HIERARCHY[role]) {
          console.log(`Assigned navigation for role ${role}`);
          return ROLE_HIERARCHY[role];
        }
      }
    }
    return [];
  }, [isLoading, isAuthenticated, roles]);

  console.log('Assigned navigation:', NAVIGATION);

  // Authentication object for AppProvider
  const authentication = React.useMemo(() => {
    return {
      signIn: () => loginWithRedirect(),
      signOut: () => logout({ logoutParams: { returnTo: window.location.origin } }),
    };
  }, [loginWithRedirect, logout]);

  return (
    <AppProvider
      session={session}
      authentication={authentication}
      navigation={NAVIGATION}
      onNavigate={(segment: string) => {
        navigate(segment ? `/${segment}` : '/');
      }}
      theme={demoTheme}
      branding={{
        logo: <img src="/logos/ODP_Logo.png" alt="ODP logo" />,
        title: (
          <Typography variant="h6" className="!font-bold font-sans" color="yellow">
            Omni Directional Power
          </Typography>)
      }}
    >
      <DashboardLayout slots={{ toolbarAccount: () => null, sidebarFooter: SidebarFooterAccount }}>
        <div
          style={{
            overflowY: 'auto',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Outlet />
        </div>
      </DashboardLayout>
    </AppProvider>
  );
}



