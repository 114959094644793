import React from 'react';
import ReactECharts from 'echarts-for-react';
import { useAtom } from 'jotai';
import { eSensorChartAtom } from '../atoms-types/eSensors';

const EsensorChart: React.FC = () => {
  const [esensorChart] = useAtom(eSensorChartAtom);

  const timestamps = esensorChart.map((item) => new Date(item.timestamp).toLocaleTimeString());
  const values = esensorChart.map((item) => item.value);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
      },
    },
    xAxis: {
      type: 'category',
      data: timestamps,
      axisLabel: {
        color: 'text.secondary',
        rotate: 45,
      },
      name: 'Time',
      nameTextStyle: {
        color: 'grey',
      },
    },
    yAxis: {
      type: 'value',
      name: 'Value',
      axisLabel: {
        formatter: '{value}',
        color: 'text.secondary',
      },
      nameTextStyle: {
        color: 'grey',
      },
    },
    series: [
      {
        name: 'Sensor Value',
        type: 'line',
        data: values,
        lineStyle: {
          color: 'gold',
        },
        itemStyle: {
          color: 'gold',
        },
        smooth: true, // Optional: Smooth the line
        tooltip: {
          valueFormatter: function (value: number) {
            return value.toString();
          },
        },
      },
    ],
  };

  return (
    <ReactECharts
      option={option}
      className="cancelSelectorName" 
      style={{ height: '400px', width: '100%' }}
      opts={{ renderer: 'canvas' }}
    />
  );
};

export default EsensorChart;
