import { atom } from 'jotai'

export interface LatLng{
  lat: number;
  lng: number;
}

export interface Zoom{
  zoom: number
}

export const mapCenterAtom = atom<LatLng>({
  lat: 42.82941,
  lng: -71.60524
})

export const mapZoomAtom = atom<Zoom>({
  zoom: 12
})