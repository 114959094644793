import React, { useState, useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ConvertTime from '../converters/timestampConverter';
import { eSensorChartAtom } from '../atoms-types/eSensors';
import { Typography, Button } from '@mui/material';

export default function EsensorChartButton({ field, device }: { field: string; device: string }) {
  const setEsensorChart = useSetAtom(eSensorChartAtom);
  const [currentDate, setCurrentDate] = useState(new Date());

  const updateDate = (direction: number) => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + direction);
      return newDate;
    });
  };

  const { refetch } = useQuery({
    queryKey: ['EsensorChart2', device, field, currentDate],
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      const formattedDate = currentDate.toISOString().split('T')[0];
      const response = await axios.get(
        `https://odp.golf/api/esensorchart/${device}/${formattedDate}`
      );
      const data = response.data;
      UpdateEsensorChart(data);
      return data;
    },
    enabled: false, // Do not run query on load
  });

  useEffect(() => {
    refetch();
  }, [currentDate, refetch]);

  function UpdateEsensorChart(data: any) {
    const updatedEsensorChart = data?.map((entry: any) => {
      const parsedJInfo = JSON.parse(entry.jInfo || '{}');
      const value = parsedJInfo[field] ?? null;
      return {
        timestamp: ConvertTime(entry.Timestamp),
        value,
      };
    });
    setEsensorChart(updatedEsensorChart);
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
      <Button
        variant="outlined"
        onClick={() => updateDate(-1)}
      >
        &#8249; {/* Left arrow */}
      </Button>
      <Typography variant="h6">
        {currentDate.toLocaleDateString('en-US')}
      </Typography>
      <Button
        variant="outlined"
        onClick={() => updateDate(1)}
        disabled={currentDate.toDateString() === new Date().toDateString()} // Disable forward button if it's today
      >
        &#8250; {/* Right arrow */}
      </Button>
    </div>
  );
}