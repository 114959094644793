import React from 'react';
import { useAtom } from 'jotai';
import { eSensorsAtom, Esensor, RH_Tank, Weather } from '../atoms-types/eSensors';
import { WidthProvider, Responsive } from 'react-grid-layout'
import { Divider, Card, Typography, CardContent, Box } from '@mui/material';
import ESensorHandler from '../handlers/environSensor-handler';
import EsensorChartHandler from '../handlers/eSensorGraph-handler';

const ResponsiveGridLayout = WidthProvider(Responsive);

type Layout = {
  i: string; // Unique identifier of the item
  x: number; // X position in grid units
  y: number; // Y position in grid units
  w: number; // Width in grid units
  h: number; // Height in grid units
  minW: number;  // min width in grid units
  minH: number; // min height in grid units
};

const EnvironGrid: React.FC = () => {
  const [eSensors,] = useAtom(eSensorsAtom);

  const RH = eSensors.find((sensors) => sensors.deviceEUI === "2CF7F1C06090018F") as Esensor & RH_Tank | undefined || { Height: 0 };
  const W = eSensors.find((sensors) => sensors.deviceEUI === "2CF7F1C06090023A") as Esensor & Weather | undefined || { TempC: 0, Humidity: 0, WindAvg: 0 };

  const layout: Layout[] = [
    { i: 'Temp', x: 0, y: 0, w: 1.5, h: 1.5, minW: 1, minH: 1.5 },
    { i: 'Humid', x: 1.5, y: 0, w: 1.5, h: 1.5, minW: 1, minH: 1.5 },
    // { i: 'Aqi', x: 0, y: 4, w: 2, h: 1.5, minW: 1, minH: 1.5 },
    { i: 'Wind', x: 3, y: 0, w: 1.5, h: 1.5, minW: 1, minH: 1.5 },
    { i: 'SensorChart', x: 0, y: 2, w: 7.5, h: 6, minW: 7.5, minH: 6 },
    { i: 'Rain', x: 4.5, y: 0, w: 1.5, h: 1.5, minW: 1, minH: 1.5 },
    { i: 'RiverHeight', x: 6, y: 0, w: 1.5, h: 1.5, minW: 1, minH: 1.5 },
  ];

  return (
    <ResponsiveGridLayout 
      className="layout" 
      layouts={{ lg: layout }} 
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }} 
      cols={{ lg: 9, md: 6, sm: 3, xs: 1 }}
      rowHeight={100}
      draggableCancel=".cancelSelectorName"
      isDraggable={false}
      isResizable={false}
      >
      <div key="Temp" style={{ minHeight: "150px" }} className='card-gauge'>
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: '10px' }}>
              <Typography variant="h3" sx={{ color: 'success.main' }}>{W.TempC ? W.TempC.toFixed(2) : "0.00"}</Typography>
              <Typography variant="body2" sx={{ color: 'success.main', ml: 0.5 }}>°C</Typography>
            </Box>
            <br />
            {/* <Divider /> */}
            <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary', textAlign: 'center', mt: '5px' }}>
              Temperature
            </Typography>
            <Divider sx={{ borderBottomWidth: '3px', borderColor: 'success.main' }} />
          </CardContent>
        </Card>
      </div>
      <div key="Humid" style={{ minHeight: "150px" }} className='card-gauge'>
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: '10px' }}>
              <Typography variant="h3" sx={{ color: 'warning.main' }}>{W.Humidity ? W.Humidity.toFixed(2) : "0.00"}</Typography>
              <Typography variant="body2" sx={{ color: 'warning.main', ml: 0.5 }}>%</Typography>
            </Box>
            <br />
            {/* <Divider /> */}
            <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary', textAlign: 'center', mt: '5px' }}>
              Humidity
            </Typography>
            <Divider sx={{ borderBottomWidth: '3px', borderColor: 'warning.main' }} />
          </CardContent>
        </Card>
      </div>
      {/* <div key="Aqi" style={{ minHeight: "150px" }} className='card-gauge'>
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: '4px' }}>
              <Typography variant="h5" sx={{ color: 'secondary.main' }}>52</Typography>
              <Typography variant="body2" sx={{ color: 'secondary.main', ml: 0.5 }}>PM2.5</Typography>
            </Box>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
              <Typography variant="h5" sx={{ color: 'secondary.main' }}>38</Typography>
              <Typography variant="body2" sx={{ color: 'secondary.main', ml: 0.5 }}>O3</Typography>
            </Box>
            <br /> */}
            {/* <Divider /> */}
            {/* <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary', textAlign: 'center', mt: '4px' }}>
              AQI
            </Typography>
            <Divider sx={{ borderBottomWidth: '3px', borderColor: 'secondary.main' }} />
          </CardContent>
        </Card>
      </div> */}
      <div key="Wind" style={{ minHeight: "150px" }} className='card-gauge'>
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: '10px' }}>
              <Typography variant="h3" sx={{ color: 'info.main' }}>{W.WindAvg ? W.WindAvg.toFixed(2) : "0.00"}</Typography>
              <Typography variant="body2" sx={{ color: 'info.main', ml: 0.5 }}>%</Typography>
            </Box>
            <br />
            {/* <Divider /> */}
            <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary', textAlign: 'center', mt: '5px' }}>
              Windspeed
            </Typography>
            <Divider sx={{ borderBottomWidth: '3px', borderColor: 'info.main' }} />
          </CardContent>
        </Card>
      </div>
      {/* <div key="Graph">
        <Divider textAlign="left">
        </Divider>
      </div> */}
      <div key="SensorChart" style={{ minHeight: "500px" }} className='card-gauge'>
        <EsensorChartHandler/>
      </div>
      <div key="Rain" style={{ minHeight: "150px" }} className='card-gauge'>
        <ESensorHandler/>
      </div>
      <div key="RiverHeight" style={{ minHeight: "150px" }} className='card-gauge'>
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: '10px' }}>
              <Typography variant="h3" sx={{ color: 'secondary.main' }}>{RH.Height ? RH.Height.toFixed(2) : "0.00"}</Typography>
              <Typography variant="body2" sx={{ color: 'secondary.main', ml: 0.5 }}>in</Typography>
            </Box>
            <br />
            {/* <Divider /> */}
            <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary', textAlign: 'center', mt: '5px' }}>
              River Height
            </Typography>
            <Divider sx={{ borderBottomWidth: '3px', borderColor: 'secondary.main' }} />
          </CardContent>
        </Card>
      </div>
    </ResponsiveGridLayout>
  );
};

export default EnvironGrid;